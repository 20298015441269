export function getFormattedTime(timezone, date) {
  let options = {
    timeZone: timezone,
    // timeZone: 'Europe/Moscow',
    hour: 'numeric',
    minute: 'numeric'
  }
  let timerFunction = new Intl.DateTimeFormat([], options)

  return timerFunction.format(date);
}

export function getFormattedDay(timezone, date) {
  let options = {
    timeZone: timezone,
    // timeZone: 'Europe/Moscow',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }
  let timerFunction = new Intl.DateTimeFormat([], options)

  return timerFunction.format(date);
}

export function getFormattedDate(timezone, date) {
  let options = {
    timeZone: timezone,
    // timeZone: 'Europe/Moscow',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }
  let timerFunction = new Intl.DateTimeFormat([], options)

  return timerFunction.format(date);
}

export function getUTCDateSeconds(date) {
  let thisDate
  if (typeof date === 'string') {
    thisDate = new Date(date)
  } else {
    thisDate = date
  }
  let options = {
    timeZone: 'UTC',
    // timeZone: 'Europe/Moscow',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }
  let timerFunction = new Intl.DateTimeFormat([], options)

  return timerFunction.format(thisDate);
}

// export function getPickerDateFromDate(date) {
//   let rawFormatDate = getFormattedDay(date)
//   console.log('rawFormatDate', rawFormatDate)
//   return rawFormatDate
// }
//
// export function exportDatefromPickerDate(pickerDate) {
//   return new Date(pickerDate)
// }

export function getDateTime2(date, universal) { //`${day}.${month}.${year}, ${hour}:${minute}`
  if (typeof date === 'string' ) {
    date = Date.parse(date + 'Z')
  }
  let timezone = universal ? 'UTC': []

  let options = {

    // timeZone: 'Europe/Moscow',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }

  if (timezone === 'UTC') {
    options.timezone = timezone
  }
  let timerFunction = new Intl.DateTimeFormat([], options)

  let result;
  try {
    result = timerFunction.format(date);
  } catch (e) {
    console.error(date, date instanceof String, typeof date, e)
  }

  // console.log('getDateTime2', date, universal, result)
  return result
}