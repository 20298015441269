<template>
  <div style="display: flex; justify-content: center; padding: 0 20px">
    <div style="display: flex; flex-direction: column; max-width: 400px; padding-top: 100px" >
      <div>
        <div style="display: flex; justify-content: center"><h4> Создание внешней телеграммы, имитирующей внешний источник</h4></div>
        <div>
          <a href="/" target="_blank">открыть основной сайт</a>
        </div>
      </div>
      <div style="padding-top: 40px">
        <md-field class="dialogField" :class="{'md-invalid': !longitudeValid}">
          <label>Долгота, град восточной долготы (-180 - 360)</label>
          <md-input v-model.trim="longitude" placeholder="Долгота"  type="number" min="-180" max="180" step="0.01"></md-input>
          <span class="md-error">Поле должно быть валидным, мин 0, макс 360</span>
        </md-field>
      </div>
      <div>
        <md-field class="dialogField" :class="{'md-invalid': !latitudeValid}" >
          <label>Широта, град северной широты (-90 - 90)</label>
          <md-input v-model.trim="latitude" placeholder="Широта"  type="number" min="-90" max="90" step="0.01"></md-input>
          <span class="md-error">Поле должно быть валидным, мин 0, макс 90</span>
        </md-field>
      </div>
      <div>
        <md-field class="dialogField" :class="{'md-invalid': !magnitudeValid}">
          <label>Магнитуда землетрясения (1-10)</label>
          <md-input v-model.trim="magnitude" placeholder="Магнитуда землетрясения"  type="number" min="1" max="10" step="0.1"></md-input>
          <span class="md-error">Поле не может быть пустым, мин 1, макс 10</span>
        </md-field>

      </div>
      <div>
        <md-field class="dialogField" :class="{'md-invalid': !depthValid}">
          <label>Глубина гипоцентр (км) (0 - 200)</label>
          <md-input v-model.trim="depth" placeholder="Магнитуда землетрясения"  type="number" min="0" max="200" step="1"></md-input>
          <span class="md-error">Поле не может быть пустым, мин 0, макс 200</span>
        </md-field>

      </div>
      <div>
        <div style="text-align: center;">
          <div v-show="!formValid"></div>
          <md-button  @click="createTelegram()" class="md-raised md-primary" style="margin-top: 40px" :disabled="loading || !formValid">Создать</md-button>
        </div>
      </div>
      <div style=" display: flex; padding-top: 50px">
        {{outputDate}}
        <br/>
        {{output}}
      </div>
    </div>
  </div>
</template>

<script>
  import logger from "../utils/logger";

  export default {
    name: 'telegramStarter',
    data() {
      return {
        loading: false,
        longitude: 154.73,
        latitude: 45.01,
        magnitude: 9.0,
        depth: 150,
        output: '',
        outputDate: ''
      }
    },
    computed: {
      formValid() {
        return this.latitudeValid && this.longitudeValid && this.magnitudeValid && this.depthValid
      },
      latitudeValid() {
        return this.latitude >=-90 && this.latitude <= 90
      },
      longitudeValid() {
        return this.longitude >=-180 && this.longitude <= 360
      },
      magnitudeValid() {
        return this.magnitude >= 1 && this.magnitude <= 10
      },
      depthValid() {
        return this.depth >= 0 && this.depth <= 200
      },
    },
    methods: {
      async createTelegram() {
        this.output = ''
        this.outputDate = ''
        this.loading = true
        let url = window.location.hostname
        // let url = '192.168.1.2:3000/'

        try {
          this.outputDate = new Date().toUTCString()
          let response = await fetch(`//${url}/send_telegram?longitude=${this.longitude}&latitude=${this.latitude}&magnitude=${this.magnitude}&depth=${this.depth}`)
          this.output = await response.json()
          logger('created', response)
        } catch (e) {
          this.output = 'Ошибка доступа к серверу'
        }


        this.loading = false
      }
    }
  }
</script>