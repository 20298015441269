const defaultState = () => ({
  coastalPointEnabled: true,
  dartEnabled: true
})

const state = defaultState();

const mutations = {
  SET_SHORE_POINT_ENABLED(state, value) {
    state.coastalPointEnabled = value
  },
  SET_DART_ENABLED(state, value) {
    state.dartEnabled = value
  },
  SWITCH_COASTAL_POINT_ENABLED(state) {
    state.coastalPointEnabled = !state.coastalPointEnabled
  },
  SWITCH_DART_ENABLED(state) {
    state.dartEnabled = !state.dartEnabled
  }
}

const getters = {
  getCoastalPointEnabled(state) {
    return state.coastalPointEnabled
  },
  getDartEnabled(state) {
    return state.dartEnabled
  }
}


const actions = {

}

export default {
  state,
  getters,
  mutations,
  actions
}