<template>
  <div >
    <div id="common" v-if="!extStarter">
      <header v-if="true" class="header-class" style="z-index: 2;
    background-color: aliceblue;
    box-sizing: border-box;"

      >
        <top-header/>
      </header>
      <div style=" z-index: 1000;  " class="main-body" :class="{'cool-background': isBackgroundPage}">
        <!--overflow-x: hidden;-->
        <router-view />
      </div>
      <!--<Test/>-->
      <footer>
        <Footer/>
      </footer>
    </div>
    <div id="starter" v-if="extStarter">
      <starter />
    </div>

  </div>
</template>

<script>
  // import Test from './Test'
  import TopHeader from "./components/TopHeader";
  import Footer from "./components/Footer";
  import logger from "./utils/logger";
  import Starter from "./views/TelegramStarter"

  export default {
    name: "App",
    data() {
      return {
        extStarter: false
      }
    },
    components: {
      TopHeader,
      Footer,
      Starter
    },
    computed: {
      isBackgroundPage() {
        return ['Registration','Registration2', 'Signin', 'Signin2', 'ResetPassword'].includes(this.$route.name)
      }
    },
    async mounted() {
      logger('app query',window.location.pathname)
      if (window.location.pathname === "/starter") {
        this.extStarter = true
      }
      //Animate being hidden after 2500ms and then remove after 500ms
      await new Promise((r) => setTimeout(r, 500));
      document.getElementById("white").classList.add("hidden");
      await new Promise((r) => setTimeout(r, 100));
      document.getElementById("white").remove();
    }
  }
</script>

<style>
  .cool-background {
    background: no-repeat center/100% url("./assets/volna_okean_vsplesk_54955_1280x1280.jpg");
    display: flex;
    align-items: center;
  }

  .main-body {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .field {
    text-align: center;
    min-width: 200px !important;

    width: 500px !important;;
    padding: 10px !important;;
    border-radius: 3rem !important;;
    font-weight: 400 !important;;
    /*font-size: 20px !important;;*/
    margin: 10px !important;;
  }

  @media (min-width: 800px) {
    .main-body.cool-background {
      max-height: 85vh;
      height: 85vh;
    }
    .main-body {
      /*overflow-y: scroll;*/
      /*height: 100%;*/
      max-height: 90vh;
      height: 75vh;
      min-height: 40vh;
    }

    .header-class {
      position: sticky;
      min-height: 50px;
      display: flex;
      width: 100%;

      /*width: 600px;*/
      top: 0%;
    }
  }

  @media (max-width: 799px) {

    h4.hide-on-small {
      /*display: none;*/
      font-size: 16px;
    }

    h1 {
      font-size: 16px;
      font-weight: 700;
    }

    .header-class {
      display: flex;
    }
  }




</style>
