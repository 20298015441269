import logger from "./logger";

export const getItem = key => {
  try {
    const value = localStorage.getItem(key);
    if (typeof value === 'undefined' || !value || value === 'undefined') {
      return null;
    }

    // console.log('getItem', value)
    return JSON.parse(value);
  } catch (error) {
    console.error('Error in getting data from localStorage', error.message)
    return null
  }
}

export const setItem = (key, data, update) => {
  let itemToWrite
  try {
    if (update) {
      let item = getItem(key)
      itemToWrite = JSON.stringify(Object.assign(item, data))
      logger('update set key', key, itemToWrite)
      localStorage.setItem(key, itemToWrite)
    } else {
      itemToWrite =  JSON.stringify(data)
      logger('init set key', key, itemToWrite)
      localStorage.setItem(key, itemToWrite)
    }
    // console.log('approve setItem', key, getItem(key))
  } catch (error) {
    console.error('Error in setting data to localStorage', error.message)
  }
}

export const clearItem = (key) => {
  try {
    logger('clearItem', key)
    localStorage.removeItem(key)
  } catch (error) {
    console.error('Error in setting data to localStorage', error.message)
  }
}
