import {getItem, setItem} from '../utils/persistanceStorage';
import axios from 'axios'
import {formAuthData} from "../utils/AuthUtil";
import logger from "../utils/logger";

const API_URL = process.env.VUE_APP_API_URL || 'https://tsunami.constant.obninsk.ru/api/v1/'
  // const BASE_URL = 'http://192.168.1.5:9091/api/v1/'
  // const BASE_URL = 'http://127.0.0.1:9091/api/v1/'
  // import {formAuthData} from "../utils/AuthUtil";
  // const instance = axios.create({baseURL: 'http://192.168.101.100:3001/api/v1/'})
  // const instance = axios.create({baseURL: 'http://127.0.0.1:3001/api/v1/'})
  // const instance = axios.create({baseURL: 'http://192.168.101.100:9091/api/v1/'})
  // const instance = axios.create({baseURL: 'http://192.168.1.5:9091/api/v1/'})
console.log('API_URL', process.env)

const instance = axios.create({baseURL: API_URL})

instance.interceptors.request.use(config => {
  // console.log('intercepter use', JSON.stringify(getItem('auth')))
  if (getItem('auth')) {
    const accessToken = getItem('auth').accessToken || null;
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
  }
  config.headers['Content-Type'] = 'application/json'

  // console.log('headers', JSON.stringify(config.headers))
  return config;
})

instance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // console.log(error.response.data)
  if (error.response.reason) {
    alert(error.response.reason)
  } else {
    if (error.response.status === 401) {
      console.log('401', 'error in response interceptor')
      //alert('пользователь не авторизован')

    }
    // if (error.response.status === 500) {
    //   alert('непредвиденная ошибка сервера')
    // }
  }
  return Promise.reject(error)
})

const wrapperModule = () => {
  const module = {}

  module.tokenRefreshTimoeout = null

  module.get = async (endpoint, content) => {
    return module.custom({method: 'GET', endpoint, content})
  }

  module.getBlob = async (endpoint, content) => {
    return module.custom({method: 'GET', endpoint, content, headersToAdd: {responseType: 'blob'}})
  }

  module.post = async (endpoint, content) => {
    return await module.custom({method: 'POST', endpoint, content})
  }


  module.custom = async ({ method, endpoint, content, headersToAdd}) => {
    try {
      // console.log('content', content, 'endpoint', endpoint)
      const headers = {};
      // if (body) {
      //   headers["Content-Type"] = body.contentType;
      // }
      // if (auth && auth.accessToken) {
      //   headers[""]
      // }
      if (headersToAdd) {
        console.log('wait blob', headersToAdd)
        Object.keys(headersToAdd).forEach(key => {
          headers[key] = headersToAdd[key]
        })
      }
      const response = await instance({
        method: method,
        url: endpoint,
        headers: headers,
        data: content ? content : undefined
      });

      // logger('response', JSON.stringify(response))
      // logger('response', response)
      let data = response.data;
      if (typeof(data) !== 'string') {
        data.success = true;
      }
      // logger('resp got', JSON.stringify(data))
      // logger('response data', JSON.stringify(data))
      return data;
    } catch (error) {
      let data = {}
      data.code = error.response ? error.response.code : undefined;
      data.data = error.response.data;
      data.status = error.response.status
      data.headers = error.response.headers
      data.success = false;

      if ((data.status == 401 || data.status == 403) && (endpoint !== ' /auth/signin' && endpoint !== ' /auth/logout')) {
        logger('resp status', data.status, 'endpoint', endpoint)
        if (getItem('auth')) {
          logger('auth', JSON.stringify(getItem('auth')))
          let tokenExpired = getItem('auth').tokenExpired
          if (tokenExpired) {
            logger('token expiration date', new Date(tokenExpired * 1000), 'current date', new Date(), 'is expired', new Date() > new Date(tokenExpired * 1000))
            if ( new Date() > new Date(tokenExpired * 1000)) {
              try {
                await module.refreshToken()
                const response2 = await instance({
                  method: method,
                  url: endpoint,
                  data: content ? content : undefined
                })
                data = response2.data;
                data.success = true;
              } catch (e) {
                logger('refresh token request or 2nd request error', e)
              }
            } else {
              logger('token is ok')
            }
          } else {
            logger('token expiration date not found')
          }
        }
      }
      return data;
    }
  }

  module.refreshToken = async () => {
    const res = await instance({
      method: 'POST',
      url: API_URL + 'auth/refresh-token',
      data: {'refreshToken': getItem('auth').refreshToken}
    })

    logger('res refresh token', res)
    if (res.status === 200) {
      let data = res.data;
      // data.success = true;
      const tokenData = data;
      let auth = formAuthData(tokenData, true)
      setItem('auth', auth, true)
      return Promise.resolve();
    }

    return Promise.reject();

  }

return module

}

export default wrapperModule
