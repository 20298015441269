// signals controller
import axiosClient from './axiosWrapper'

export const getSignals = async () => { //no params
  let result = await axiosClient().get('/signals')

  return result
}

export const closeSignal = async (id) => {
  let result = await axiosClient().post(`/signals/${id}/close`)
  return result
}

export const getSignal = async (signalId) => {
  let result = await axiosClient().get(`/signals/${signalId}`)

  return result
}

//
// "earthquakeDate": "2024-03-31T23:00:46.167Z",
//   "longitude": 180,
//   "latitude": 90,
//   "magnitude": 10,
//   "depth": 100,
//   "tsunamigenic": true,
//   "exercise": true,
//   "closed": "2024-03-31T23:00:46.167Z"
export const postSignal = async (earthquakeDate, exercise, latitude, longitude, magnitude, depth, tsunamigenic) => {
  let data = { exercise, latitude, longitude, magnitude, depth, tsunamigenic}
  data.earthquakeDate = earthquakeDate
  let result = await axiosClient().post('/signals', data)
  return result
}