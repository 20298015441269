import axiosClient from "./axiosWrapper";
import logger from "../utils/logger";

export const getLocations = async () => { //no params
  let result = await axiosClient().get('/locations')
  logger('getLocation', result)

  if (result.success) {
    return result.payload
  } else {
    return []
  }


}