function splitToken(bearerToken){
  var base64Url = bearerToken.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let raw = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''))
  return JSON.parse(raw)
}

export function formAuthData(response, update) {
  let decrypted = splitToken(response.payload.accessToken)


  let data = {
    accessToken: response.payload.accessToken,
    tokenCreated: decrypted.iat,
    tokenExpired: decrypted.exp,
    username: decrypted.sub,
    refreshToken: response.payload.refreshToken,
  }

  if (!update) {
    data.roles = response.payload.roles
  }
  return data
}