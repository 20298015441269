export default class AudioLoader {
  constructor () {
    this.loadSound = (path) => {
      const sound = new window.Audio(path)
      sound.load()
      sound.volume = 1
      return sound
    }
    this.signalSound = this.loadSound('..audio/alert.wav')
    this.messageSound = this.loadSound('../mail.wav')
  }

  signal () {
    return this.signalSound
  }

  message () {
    return this.messageSound
  }

}
