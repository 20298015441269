// authentication controller
import logger from "../utils/logger";
import axiosClient from "./axiosWrapper";
import {clearItem, setItem} from "../utils/persistanceStorage";
import {formAuthData} from "../utils/AuthUtil";

export const signup = async (username, surname, name, patronymic, phone, organization, roles, password ) => {
  logger('signup', username, surname, name, patronymic, phone, organization, roles )
  // let result = await axiosClient().post('/auth/signup',`{"email": "${username}", "password": "1234", "lastName": "${lastName}", "name": "${name}", "patronymic": "${patronymic}", "phone": "${phone}", "organization": "${organization}", "roles": ["${roles}"]}, fullName: "${lastName} ${name} ${patronymic}"`)
  let result = await axiosClient().post('/auth/signup',`{"email": "${username}", "username": "${username}", "password": "${password}", "lastName": "${surname}", "firstName": "${name}", "patronymic": "${patronymic}", "phone": "${phone}", "organization": "${organization}", "roles": ["${roles}"]}`)
  logger('signup result ', result)
  return result
}


export const signin = async (username, password, force) => {
  logger(JSON.stringify(axiosClient()))
  let body = `{"username": "${username}", "password": "${password}", "force": "${!!force}"}`
  let result = await axiosClient().post('/auth/signin', body)

  if (result.success) {
    logger('result login', result)
    let auth = formAuthData(result)
    logger('auth logged', auth)
    setItem('auth', auth)
    return 1
  } else {
    return Promise.reject(result)
  }
}

// export const refreshTpken = async (refreshToken) => {
//   logger('refreshToken', refreshToken)
//   let result = await axiosClient().post('/auth/refresh', {refreshToken: getItem('auth').refreshToken})
//   return result
// }


export const logout = async () => {
  // logger(JSON.stringify(axiosClient()))
  try {
    let result = await axiosClient().post('/auth/logout')
    logger('goLogout result', JSON.stringify(result))
  } finally {
    clearItem('auth')
  }
  return 1
}