import Vue from 'vue'
import VueRouter from 'vue-router'
import {getItem} from "./utils/persistanceStorage";
import {timeFromSeconds} from "./utils/time";

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    alias: '/signin',
    name: 'Signin',
    component: () => import('./views/Login.vue'),
    meta: { auth: false, frame: false}
  },
  {
    path: '/signin2',
    name: 'Signin2',
    component: () => import('./components/Login2.vue'),
    meta: { auth: false, frame: false}
  },
  {
    path: '/signin-done',
    name: 'LoginDone',
    component: () => import('./components/LoginDone.vue'),
    meta: { auth: true, frame: true }
  },
  {
    path: '/signup',
    name: 'Registration',
    component: () => import('./views/Registration.vue'),
    meta: { auth: false, frame: false }
  },
  {
    path: '/signup2',
    name: 'Registration2',
    component: () => import('./components/Registration2.vue'),
    meta: { auth: false, frame: false }
  },
  {
    path: '/signup-done',
    name: 'RegistrationDone',
    component: () => import('./components/RegistrationDone.vue'),
    meta: { auth: false, frame: false }
  },
  {
    path: '/reset-password/:tempPassword',
    alias:'/reset-password',
    name: 'ResetPassword',
    component: () => import('./views/ResetPassword.vue'),
    meta: { auth: false, frame: false }
  },
  {
    path: '/reset-password-done',
    name: 'ResetPasswordDone',
    component: () => import('./views/ResetPasswordDone.vue'),
    meta: { auth: false, frame: false }
  },
  {
    path: '/sea-data',
    name: 'SeaData',
    component: () => import('./views/SeaData.vue'),
    meta: { auth: true, frame: true }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('./views/Users.vue'),
    meta: { auth: true, frame: true }
  },
  {
    path: '/logs',
    name: 'Logs',
    component: () => import('./views/Logs.vue'),
    meta: { auth: true, frame: true }
  },
  {
    path: '/expert/documents',
    name: 'ExpertDocs',
    component: () => import('./views/ExpertDocs.vue'),
    meta: { auth: true, frame: true, role: 'expert' }
  },
  {
    path: '/expert/messages',
    name: 'ExpertDocs',
    component: () => import('./views/TriggeringMessags.vue'),
    meta: { auth: true, frame: true, role: 'expert' }
  },
  {
    path: '/users2',
    name: 'Users2',
    component: () => import('./components/Users2.vue'),
    meta: { auth: true, frame: true }
  },
  {
    path: '/oceanographer',
    name: 'Oceanographer',
    component: () => import('./views/Oceanographer.vue'),
    meta: { auth: true, frame: true }
  },
  {
    path: '/seismologist',
    name: 'Seismologist',
    component: () => import('./views/Seismologist.vue'),
    meta: { auth: true, frame: true }
  },
  {
    path: '/expert/:id',
    name: 'Expert',
    component: () => import('./views/Expert.vue'),
    meta: { auth: true, frame: true }
  },
  {
    path: '/expert',
    name: 'Expert',
    component: () => import('./views/ExpertEmpty.vue'),
    meta: { auth: true, frame: true, role: 'expert' }
  },
  {
    path: '/wrongRole',
    name: 'WrongRole',
    component: () => import('./views/WrongRole.vue'),
    meta: { auth: true, frame: true }
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('./Test.vue'),
    meta: { auth: false, frame: true }
  },
  {
    path: '/preview',
    name: 'Preview',
    component: () => import('./components/Preview.vue'),
    meta: { auth: false, frame: true }
  },
  {
    path: '/testtiff',
    name: 'Test geotiff',
    component: () => import('./views/GeotiffViewer'),
    meta: {auth: false, frame: false}
  }
]

const router = new VueRouter({
  mode: 'history',
  hashbang: false,
  linkActiveClass: 'active',
  saveScrollPosition: true,
  routes
});

router.beforeEach((to, from, next) => {
// console.log('beforeEach', JSON.stringify(to), from, !!next, 'auth', JSON.stringify(getItem('auth')) )
//   console.log('before each', new Date() < timeFromSeconds(getItem('auth').tokenExpired), new Date(),new Date(getItem('auth').tokenExpired))
  if (!to.meta.auth || (getItem('auth')) && (new Date() < timeFromSeconds(getItem('auth').tokenExpired))) {
    // if (!getItem('auth').roles.includes(to.meta.role)) {
    //   next({path: '/signin', query: { redirect: to.fullPath }})
    // } else {
      next()
    // }
  } else {
    next({path: '/signin', query: { redirect: to.fullPath }})
  }
});

export default router
